/* projects.css */

/* Container styles for the projects section */
.projects-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  padding-left: 200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px;
}

.projects-header {
  text-align: left;
  width: 100%;
  padding-left: 0;
  margin-bottom: 20px;
}

/* Styles for individual project cards */
.project-card {
  background-color: #333;
  color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  width: 300px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.3s;
  position: relative;
}

.project-card:hover {
  transform: scale(1.05);
}

/* Header styles within the project card */
.project-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.project-title {
  flex: 1;
  text-align: left;
}

/* Icon container styles */
.icons-container {
  display: flex;
  gap: 8px;
}

/* Description styles for projects */
.project-description {
  font-size: 0.9rem;
  margin: 10px 0;
  text-align: left;
}

/* Tech stack styling within projects */
.tech-stacks {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
}

.tech-chip {
  background-color: #555;
  color: #9f9f9f;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 0.8rem;
  cursor: default;
  margin: 2px 0;
}

/* Link styling within projects */
.project-links {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.project-link {
  color: #FF4545;
  margin-top: 10px;
}

.project-link svg {
  fill: #FF4545;
}

.project-link:hover {
  text-decoration: none;
}

/* Preview container styles */
.preview-container {
  width: 100%;
  height: 200px;
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  margin-top: 15px;
}

.preview-iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.full-view-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: rgba(255,255,255,0.7) !important;
  padding: 5px !important;
}

.full-view-button:hover {
  background-color: rgba(255,255,255,0.9) !important;
}

/* Responsive adjustments for mobile view */
@media (max-width: 768px) {
  .projects-container {
    padding: 5vh 5vw;
    justify-content: center;
  }

  .projects-header {
    text-align: center;
    padding: 0;
  }

  .project-card {
    width: 100%;
    max-width: 400px;
    margin-bottom: 4vh;
  }

  .project-title {
    font-size: 1.2rem;
  }

  .project-description {
    font-size: 0.9rem;
  }

  .tech-stacks {
    justify-content: center;
  }

  .tech-chip {
    font-size: 0.75rem;
  }

  .preview-container {
    height: 180px;
  }

  .full-view-button {
    padding: 3px !important;
  }
}

/* Additional adjustments for smaller screens */
@media (max-width: 430px) {
  .project-title {
    font-size: 1.1rem;
  }

  .project-description {
    font-size: 0.8rem;
  }

  .tech-chip {
    font-size: 0.7rem;
    padding: 3px 6px;
  }

  .preview-container {
    height: 150px;
  }
}