/* General styles */
.home-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  min-height: auto;
  padding-top: 25vh;
  padding-left: 200px;
  padding-right: 200px;
  padding-bottom: 0;
}

.david-text {
  color: #FF4545;
}

/* Typography styles */
.typography-h1 {
  margin: 0;
  font-size: 6rem;
  font-weight: bold;
}

/* Profile image styles */
.profile-image {
  position: absolute;
  right: 20%;
  top: 25vh;
  width: 250px;
  height: 250px;
  object-fit: cover;
  border-radius: 50%;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .profile-image {
    right: 10%;
  }
}

@media (max-width: 768px) {
  .home-container {
    padding: 5vh 5vw;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
  }

  .profile-image {
    position: static;
    margin: 5vh auto;
    width: 200px;
    height: 200px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.1);
  }

  .typography-h1 {
    font-size: 2.5rem;
    line-height: 1.2;
    margin-top: 3vh;
  }
}

@media (max-width: 600px) {
  .home-container {
    padding: 5vh 5vw;
  }

  .typography-h1 {
    font-size: 2rem;
  }

  .profile-image {
    width: 150px;
    height: 150px;
  }
}

/* Additional adjustments for iPhone 14 Pro Max in portrait mode */
@media (max-width: 720px) {
  .home-container {
    justify-content: center;
  }

  .typography-h1 {
    font-size: 2.2rem;
  }
}