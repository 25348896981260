/* Experience.css */

/* Container styles */
.experience-container {
  display: flex;
  color: #FFFFFF;
  padding-top: 20px;
  padding-left: 0;
  padding-right: 200px;
  position: relative;
}

/* Experience selector styles */
.experience-selector {
  position: absolute;
  left: 200px;
  top: 50px;
  width: 250px;
  text-align: left;
}

/* Company name styles */
.company-name {
  cursor: pointer;
  margin-bottom: 15px;
  transition: color 0.3s;
}

.company-name.active {
  color: #FF4545;
  font-weight: bold;
}

/* Experience details styles */
.experience-details {
  flex-grow: 1;
  margin-left: 300px;
  padding-left: 20px;
  padding-right: 20px;
  border-left: 2px solid #FF4545;
}

/* Text alignment for experience details */
.experience-title,
.experience-company,
.experience-duration {
  text-align: left;
}

.experience-responsibilities {
  padding-left: 0;
}

/* Experience item styles */
.experience-item {
  display: flex;
  align-items: flex-start;
  font-size: 16px;
  word-wrap: break-word;
  max-width: 600px;
}

.item-icon {
  color: #FF4545;
  margin-right: 10px;
  font-size: 16px;
  width: 20px;
}

/* Title header styles */
.title-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 200px;
}

/* Responsive adjustments for mobile view */
@media (max-width: 768px) {
  .experience-container {
    flex-direction: column;
    padding: 5vh 5vw;
  }

  .experience-selector {
    position: static;
    width: 100%;
    margin-bottom: 4vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .company-name {
    margin: 0 10px 10px;
    padding: 5px 10px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 20px;
  }

  .experience-details {
    margin-left: 0;
    padding: 0;
    border-left: none;
  }

  .experience-item {
    font-size: 0.9rem;
    margin-bottom: 2vh;
  }

  .title-header {
    padding-left: 0;
    flex-direction: column;
    align-items: flex-start;
  }

  .experience-title,
  .experience-company,
  .experience-duration {
    margin-bottom: 1vh;
  }

  .experience-responsibilities {
    margin-top: 2vh;
  }
}

/* Additional adjustments for iPhone 14 Pro Max in portrait mode */
@media (max-width: 430px) {
  .experience-item {
    font-size: 0.8rem;
  }

  .company-name {
    font-size: 0.9rem;
  }
}