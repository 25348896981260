/* About.css */

/* General container styles */
.about-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: auto;
  padding-top: 0;
  padding-left: 200px;
}

/* Typography and text styling */
.typography-body1 {
  font-size: 22px;
  max-width: 40%;
  text-align: left;
  padding-bottom: 5px;
  color: #FFFFFF;
}

/* Tech stack styles */
.tech-stack {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  list-style: none;
  padding: 0;
  color: #FFFFFF;
}

.tech-item {
  display: flex;
  align-items: center;
  font-size: 1rem;
}

.tech-item::before {
  content: '►';
  color: #FF4545;
  margin-right: 10px;
}

.project-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

/* Responsive adjustments for mobile view */
@media (max-width: 768px) {
  .about-container {
    padding: 5vh 5vw;
    align-items: center;
  }

  .typography-body1 {
    font-size: 1rem;
    max-width: 100%;
    text-align: center;
    margin-bottom: 3vh;
  }

  .tech-stack {
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    justify-content: center;
  }

  .tech-item {
    font-size: 0.9rem;
  }

  .project-card {
    width: 100%;
  }

  .project-header {
    flex-direction: column;
    align-items: flex-start;
  }
}

/* Additional adjustments for iPhone 14 Pro Max in portrait mode */
@media (max-width: 430px) {
  .typography-body1 {
    font-size: 0.9rem;
  }

  .tech-stack {
    grid-template-columns: 1fr;
  }

  .tech-item {
    font-size: 0.8rem;
  }
}